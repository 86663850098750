import React, { useEffect, useState } from 'react';
import Text from "@shared/ui/Text";
import { useAuth } from '@shared/AuthContext';
import formatNumber from '@shared/helpers/formatNumber';
import {HeaderContainer, ExitButton, Icon, CalendarContainer, StyledPrice, UserInfo, Username, UserButton} from "./styles";
import { useNavigate } from 'react-router-dom';
import LogoutButton from '@features/LogoutButton';
import getHeaderRatesApi from '@shared/api/services/header/getHeaderRatesApi';
import getBalanceApi from "@shared/api/services/getBalanceApi";
import getShopsApi from "@shared/api/services/merchant/getShopsApi";
import makeRequest from "@shared/api/makeRequest";


const Price = ({value, currency}) => {
  const formattedNumber = formatNumber(value);

  return <StyledPrice>
    <Text>{formattedNumber} {currency}</Text>
  </StyledPrice>
}


const Header = ({ username, toggleSidebar, isSidebarVisible }) => {
  const {user } = useAuth();
  const userRole = user?.role;
  const navigate = useNavigate();

  const [rates, setRates] = useState([]);
  const [balance, setBalance] = useState();
  const [disputes, setDisputes] = useState();

  useEffect(fetchRates, []);
  useEffect(initLoopFetching, []);
  useEffect(fetchBalance, []);

  return (
    <HeaderContainer isSidebarVisible={isSidebarVisible}>
      <ExitButton onClick={toggleSidebar}>
        <Icon src='/icons/menu.svg'/>
      </ExitButton>
      <div style={{display: "flex", flexWrap: "wrap", gap: "10px", justifyContent: "center"}}>
        {balance !== undefined && <Rate label="Баланс" symbol="USDT" price={balance} />}
        {rates.map(({label, symbol, price}) => <Rate label={label} symbol={symbol} price={price} />)}
          {disputes !== undefined && <CalendarContainer>
            <p style={{margin: '0 25px 0 0', fontSize: "12px", color: 'var(--second_text)'}}><Text>Диспуты</Text></p>
            {disputes}
          </CalendarContainer>}
      </div>
      <UserInfo>
        {/* <AlertButton>
          <Icon src="../static/icons/Bell.png" className="icon-alert" />
        </AlertButton> */}
        {userRole === 'admin' && (
        <UserButton onClick={() => navigate('/invite')}>
          <Icon src="/icons/user-add.svg" className="icon-user" />
        </UserButton>)}
        <LogoutButton />
        
        <Username>{username}</Username>
      </UserInfo>
    </HeaderContainer>
  );

  function fetchRates() {
    (async () => {
      try {
        const {rates, disputes} = await getHeaderRatesApi();

        setRates(rates);
        setDisputes(disputes);
      } catch(e) {console.log(e)}
    })();
  }

  function fetchBalance() {
    (async () => {
      try {
        let balance;

        if(user.role === "merchant") {
          const {overall_statistics} = await makeRequest("/api/v1/wallets/balance/shops", { method: "GET" });
          balance = overall_statistics.total_balance;
        } else {
          const {total_balance} = await getBalanceApi();
          balance = total_balance;
        }

        setBalance(balance);
      } catch(e) {
        console.log(e);
      }
    })();
  }

  function initLoopFetching() {
    const interval = setInterval(fetchRates, 60000);

    return () => {
        clearInterval(interval);
    };
  }
};

function Rate({currency, label, symbol, price}) {
  return <CalendarContainer>
    <p style={{margin: '0 5px 0 0', fontSize: "12px", color: 'var(--second_text)'}}><Text>{label}</Text></p>
    <Price currency={symbol} value={price}/>
  </CalendarContainer>
}

export default Header;
