import React from 'react';
import { Input } from 'antd';
import 'antd/dist/reset.css'; // Ант Дизайн стили
import styled from 'styled-components';
import Text from "@shared/ui/Text";
import { sizes } from '../../app/constants';
import { InputNumber } from 'antd';
import Label from './Label';
import ErrorLabel from './ErrorLabel';


const StyledInput = styled(Input)`
    &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px white inset !important;
        -webkit-box-shadow: 0 0 0px 1000px white inset !important;
        -webkit-text-fill-color: #000 !important;
    }
    margin: 0;
`;

const StyledPassword = styled(Input.Password)`
    &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px white inset !important;
        -webkit-box-shadow: 0 0 0px 1000px white inset !important;
        -webkit-text-fill-color: #000 !important;
    }
    margin: 0;
`;

const SimpleInput = ({ textarea, label, error, min, max, prefix, number, passwordType, ...props }) => {
  // Используем StyledPassword для паролей и StyledInput для других инпутов
  const Component = passwordType ? StyledPassword : StyledInput;

  return (
    <ErrorLabel value={error}>
      <Label value={label}>
        {number ? 
          <InputNumber min={min} max={max} prefix={prefix} status={error && "error"} style={{ fontSize: sizes.default, width: "100%" }} {...props} /> 
          : 
          <Component min={min} max={max} prefix={prefix} status={error && "error"} style={{ fontSize: sizes.default, width: "100%" }} {...props} />}
      </Label>
    </ErrorLabel>
    
  );
};

export default SimpleInput;
