import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom'; // Импортируем useNavigate и useLocation
import { useAuth } from '@shared/AuthContext';
import Text from "@shared/ui/Text";

const Logo = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--accent);
  margin: 0;
  text-align: center;
`;

const SidebarContainer = styled.div`
  width: calc(100vw / 7);
  position: fixed;
  background-color: var(--primary);
  padding: 26px 18px;
  border-right: solid 1px var(--border);
  height: 100vh;
  z-index: 1000;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  min-width: 170px;
  justify-content: space-between;
  gap: 20px;
  flex: 1;

  @media (max-width: 1200px) {
    position: absolute;
    width: 30%;
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 12px;
`;

const MainButtonsWithLogo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 47px;
`;

const MainButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const HelpBtns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  width: auto;
  padding: 8px 16px;
  background-color: ${({ active }) => (active ? 'RGB(236,239,244)' : 'transparent')};
  border-radius: 12px;
  border: none;
  color: ${({ active }) => (active ? 'var(--text)' : 'var(--second_text)')};
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease, border-radius 0.3s ease;

  &:hover {
    background-color: RGB(231,234,240);
    border-radius: 12px;
    color: #1f1f1f;
  }
`;

const Sidebar = ({ isVisible, closeSidebar }) => {
  const { user } = useAuth();
  const navigate = useNavigate(); // Используем для навигации
  const location = useLocation(); // Получаем текущий маршрут

  // Функция для проверки, активен ли маршрут
  const isActive = (path) => {
    return location.pathname === path || location.pathname.startsWith(`${path}/`);
  };

  const redirect = (path) => {
    navigate(path);
    if(window.innerWidth <= 1200) { // Закрываем сайдбар при нажатии на раздел на маленьких экранах
      closeSidebar();
    }
  };

  function getButtons() {
    const commonButtons = [
      { src: '/icons/moneys.svg', path: '/assets', title: 'Активы' },
      { src: '/icons/card-coin.svg', path: '/orders', title: 'Ордера' },
      { src: '/icons/candle.svg', path: '/statistics', title: 'Статистика' }
    ];
    
    const roleSpecificButtons = {
      trader: [
        { src: '/icons/card-edit.svg', path: '/hubs', title: 'R-Hubs' },
        { src: '/icons/edit-2.svg', path: '/devices', title: 'Устройства' },
        { src: '/icons/card-edit.svg', path: '/phubs', title: 'P-Hubs' },
      ],
      merchant: [
        { src: '/icons/shop.svg', path: '/shops', title: 'Магазины' },
        { src: '/icons/archive-tick.svg', path: '/archive', title: 'Архив' },
      ],
      admin: [
        { src: '/icons/shop.svg', path: '/shops', title: 'Магазины' },
        { src: '/icons/card-edit.svg', path: '/traders', title: 'Трейдеры' },
        { src: '/icons/archive-tick.svg', path: '/archive', title: 'Архив' },
        { src: '/icons/card-edit.svg', path: '/agents', title: 'Агенты' },
      ],
      support: []
    };
    
    function getButtonsForRole(role) {
      const buttons = [...commonButtons, ...(roleSpecificButtons[role] || [])];
      return buttons.map(el => (
        <Button key={el.path} onClick={() => redirect(el.path)} active={isActive(el.path)}>
          <Icon src={el.src} />
          <Text weight={isActive(el.path) ? "bold" : "default"}>{el.title}</Text>
        </Button>
      ));
    }
    
    return {
      trader: <>{getButtonsForRole('trader')}</>,
      merchant: <>{getButtonsForRole('merchant')}</>,
      admin: <>{getButtonsForRole('admin')}</>,
      support: <>{getButtonsForRole('support')}</>
    };
  }

  const Buttons = useMemo(getButtons, [location.pathname]);

  return (
    <SidebarContainer isVisible={isVisible}>
      <MainButtonsWithLogo>
        <Logo><img src='/icons/лого.svg' alt="Logo" /></Logo>
        <MainButtons> 
          {Buttons[user.role]}
        </MainButtons>
      </MainButtonsWithLogo>
      <HelpBtns>
        <Button onClick={() => navigate('/settings')} active={isActive('/settings')}>
          <Icon src='/icons/setting.svg' />
          <Text weight={isActive('/settings') ? "bold" : "default"}>Настройки</Text>
        </Button>
        <Button onClick={() => navigate('/help')} active={isActive('/help')}>
          <Icon src='/icons/star.svg' />
          <Text weight={isActive('/help') ? "bold" : "default"}>Помощь</Text>
        </Button>
      </HelpBtns>
    </SidebarContainer>
  );
};

export default Sidebar;
