import React from "react";
import { Button } from "antd";
import Text from "../../Text";
import sizes from "./presets/sizes";
import colors from "./presets/colors";
import Loading from "@shared/ui/Loading";


const SimpleButton = ({ loading, type, antdType = "primary", Icon, color, size, children, onClick, fullWidth = true, disabled, ...rest } ) => {
    const Component = <Button
        htmlType={type}
        disabled={disabled}
        style={{background: "black", color: "white"}}
        onClick={disabled || loading ? undefined : onClick}
        {...rest}
    >
        <div style={{display: "flex", justifyContent: "start", gap: "10px", alignItems: "center"}}>
            {loading ? <Loading size="xsmall" color="white"/> : Icon && <div>{Icon}</div>}
            <div>{children}</div>
        </div>
    </Button>

    if(fullWidth) return Component;
    return <div>{Component}</div>
};

export default SimpleButton;