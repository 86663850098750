import styled from "styled-components";


const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
`;

const StyledLabel = styled.label`
  font-size: 12px;
  color: 	#D22B2B;
`;

const ErrorLabel = ({value, children}) => {
    return <FormGroup>
        {children}
        {value && <StyledLabel>{value}</StyledLabel>}
    </FormGroup>
}

export default ErrorLabel;